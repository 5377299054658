import axios from 'axios'
const BASE_API_URL = process.env.REACT_APP_API_URL

// =================dashboard URL=====================
export const GET_ADMIN_COUNT = `${BASE_API_URL}/Dashboard/GetAdminDashboard` //Get Admin Count

// =========================Get Admin=====================
export function getAdminCountApi() {
  return axios.post(GET_ADMIN_COUNT)
}
