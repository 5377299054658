import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_deltaline/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // ============================ Master ========================
  const CountryMasterPage = lazy(
    () => import('../modules/master-page/country-master-page/CountryMasterPage')
  )
  // ================================================================
  const StateMasterPage = lazy(
    () => import('../modules/master-page/state-master-page/StateMasterPage')
  )
  // ================================================================
  const DistrictMasterPage = lazy(
    () => import('../modules/master-page/district-master-page/DistrictMasterPage')
  )
  // ================================================================
  const TalukaMasterPage = lazy(
    () => import('../modules/master-page/taluka-master-page/TalukaMasterPage')
  )
  // ================================================================
  const NationalityMasterPage = lazy(
    () => import('../modules/master-page/nationality-master-page/NationalityMasterPage')
  )
  // ================================================================
  const EducationCategoryPage = lazy(
    () => import('../modules/master-page/education-category-master-page/EducationCategoryPage')
  )
  // ================================================================
  const EducationDepartmentPage = lazy(
    () => import('../modules/master-page/education-department-master-page/EducationDepartmentPage')
  )
  // ================================================================
  const PincodeMasterPage = lazy(
    () => import('../modules/master-page/pincode-master-page/PincodeMasterPage')
  )
  // ================================================================
  const ScriptMasterPage = lazy(
    () => import('../modules/master-page/srcipt-master-page/ScriptMasterPage')
  )
  // ================================================================
  const TaxMasterPage = lazy(() => import('../modules/master-page/tax-master-page/TaxMasterPage'))
  // ================================================================
  const ScriptSectorMasterPage = lazy(
    () => import('../modules/master-page/script-sector-master-page/ScriptSectorMasterPage')
  )
  // ================================================================
  const DocumentTypePage = lazy(
    () => import('../modules/master-page/document-type-page/DocumentTypePage')
  )
  // ================================================================
  const KycDocumentMasterPage = lazy(
    () => import('../modules/master-page/kyc-document-page/KycDocumentMasterPage')
  )
  // ================================================================
  const TerminalTypeMasterPage = lazy(
    () => import('../modules/master-page/terminal-type-page/TerminalTypeMasterPage')
  )
  // ================================================================
  const StockMasterPage = lazy(() => import('../modules/master-page/stock-page/StockMasterPage'))
  // ================================================================
  const ExpiryDateMasterPage = lazy(
    () => import('../modules/master-page/expiry-date-master-page/ExpiryDateMasterPage')
  )
  // ================================================================
  const ExchangeMasterPage = lazy(
    () => import('../modules/master-page/exchange-master-page/ExchangeMasterPage')
  )
  // ================================================================
  const StrikePriceMasterPage = lazy(
    () => import('../modules/master-page/strike-price-master-page/StrikePriceMasterPage')
  )
  // ================================================================
  const OptionTypeMasterPage = lazy(
    () => import('../modules/master-page/option-type-master-page/OptionTypeMasterPage')
  )
  // ================================================================
  const ChargesMasterPage = lazy(
    () => import('../modules/master-page/charges-master-page/ChargesMasterPage')
  )
  // ================================================================
  const ChargesNameMasterPage = lazy(
    () => import('../modules/master-page/charges-name-master-page/ChargesNameMasterPage')
  )
  // ================================================================
  const CustomerExpenseTypeMasterPage = lazy(
    () =>
      import(
        '../modules/master-page/customer-expense-type-master-page/CustomerExpenseTypeMasterPage'
      )
  )
  // ================================================================
  const JournalVoucherTypeMasterPage = lazy(
    () =>
      import('../modules/master-page/journal-voucher-type-master-page/JournalVoucherTypeMasterPage')
  )
  // ================================================================
  const VoucherCategoryMasterPage = lazy(
    () =>
      import(
        '../modules/master-page/journal-voucher-category-master-page/JournalVoucherCategoryMasterPage'
      )
  )
  const VoucherGbSubCategoryMasterPage = lazy(
    () =>
      import(
        '../modules/master-page/voucher-gb-subcategory-master-page/VoucherGbSubCategoryMasterPage'
      )
  )
  // ================================= Organization ==========================
  const EmployeeMasterPage = lazy(
    () => import('../modules/organization-page/employee-master-page/EmployeeMasterPage')
  )
  // ================================================================
  const UserMasterPage = lazy(
    () => import('../modules/organization-page/user-master-page/UserMasterPage')
  )
  // ================================================================
  const CustomerMasterPage = lazy(
    () => import('../modules/organization-page/customer-master-page/CustomerMasterPage')
  )
  // ================================================================
  const DepartmentMasterPage = lazy(
    () => import('../modules/master-page/department-master-page/DepartmentMasterPage')
  )
  // ================================================================
  const DesignationMasterPage = lazy(
    () => import('../modules/master-page/designation-master-page/DesignationMasterPage')
  )
  // ================================================================
  const BranchMasterPage = lazy(
    () => import('../modules/master-page/branch-master-page/BranchMasterPage')
  )
  // ================================================================
  const SegmentTypeMasterPage = lazy(
    () => import('../modules/master-page/segment-type-master-page/SegmentTypeMasterPage')
  )
  // ================================================================
  const InstrumentTypeMasterPage = lazy(
    () => import('../modules/master-page/Instument-type-master-page/InstrumentTypeMasterPage')
  )
  // ================================================================
  const OrganizationBankMasterPage = lazy(
    () => import('../modules/master-page/organization-bank-master-page/OrganizationBankMasterPage')
  )
  // ================================================================
  const ChangePasswordPage = lazy(() => import('../modules/change-password/ChangePasswordPage'))
  // ================================================================
  const LedgerMasterPage = lazy(() => import('../modules/ledger-page/LedgerMasterPage'))
  // ================================================================
  const JobCardMasterPage = lazy(
    () => import('../modules/jobs-page/job-card-page/JobCardMasterPage')
  )
  // ================================================================
  const JobTransactionMasterPage = lazy(
    () => import('../modules/jobs-page/job-transaction-page/JobTransactionMasterPage')
  )
  // ================================================================
  const JobGenerateMasterPage = lazy(
    () => import('../modules/jobs-page/job-generate-page/JobGenerateMasterPage')
  )
  // ================================================================
  const CustomerExpenseMasterPage = lazy(
    () => import('../modules/customer-expense-page/CustomerExpenseMasterPage')
  )
  // ================================================================
  const WithdrawRequestMasterPage = lazy(
    () =>
      import(
        '../modules/payment-master-page/withdraw-request-master-page/WithdrawRequestMasterPage'
      )
  )
  // ================================================================
  const PaymentWithdrawMasterPage = lazy(
    () =>
      import(
        '../modules/payment-master-page/payment-withdraw-master-page/PaymentWithdrawMasterPage'
      )
  )
  // ================================================================
  const TransactionMasterPage = lazy(
    () => import('../modules/payment-master-page/transaction-master-page/TransactionMasterPage')
  )
  const TerminalCodePage = lazy(
    () => import('../modules/terminal-code-master-page/TerminalCodePage')
  )
  // ================================================================
  const FundMasterPage = lazy(
    () => import('../modules/payment-master-page/fund-master-page/FundMasterPage')
  )
  // ================================================================
  const UserDetailsMasterPage = lazy(
    () => import('../modules/user-document-page/user-details-page/UserDetailsMasterPage')
  )
  const TermSheetMasterPage = lazy(
    () => import('../modules/user-document-page/term-sheet-page/TermSheetMasterPage')
  )
  const RentAgreementMasterPage = lazy(
    () => import('../modules/user-document-page/rent-agreement-page/RentAgreementMasterPage')
  )
  const JobbingAgreementMasterPage = lazy(
    () => import('../modules/user-document-page/jobbing-agreement-page/JobbingAgreementMasterPage')
  )
  // =============================== OpenPosition  ====================================
  const OpenPositionMasterPage = lazy(
    () => import('../modules/open-position-page/OpenPositionMasterPage')
  )
  // =============================== Bhav Copy ====================================
  const BhavCopyMasterPage = lazy(
    () => import('../modules/bhav-copy-master-page/BhavCopyMasterPage')
  )
  // =============================== journalVouchers ====================================
  const JournalVouchersMasterPage = lazy(
    () => import('../modules/journal-vouchers-master-page/JournalVouchersMasterPage')
  )
  // =============================== journalVouchers ====================================
  const ProfitLossMasterPage = lazy(
    () => import('../modules/profit-loss-master-page/daily-profit-loss/ProfitLossMasterPage')
  )
  // =============================== journalVouchers ====================================
  const ExpiryDaywiseMasterPage = lazy(
    () => import('../modules/profit-loss-master-page/expiry-daywise/ExpiryDaywiseMasterPage')
  )

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Route path='/master/country' component={CountryMasterPage} />
        <Route path='/master/state' component={StateMasterPage} />
        <Route path='/master/district' component={DistrictMasterPage} />
        <Route path='/master/taluka' component={TalukaMasterPage} />
        <Route path='/master/nationality' component={NationalityMasterPage} />
        <Route path='/master/scriptmanage' component={ScriptMasterPage} />
        <Route path='/master/scriptsector' component={ScriptSectorMasterPage} />
        <Route path='/master/pincode' component={PincodeMasterPage} />
        <Route path='/master/segmentType' component={SegmentTypeMasterPage} />
        <Route path='/master/instrumentType' component={InstrumentTypeMasterPage} />
        <Route path='/master/eduDepartment' component={EducationDepartmentPage} />
        <Route path='/master/eduCategory' component={EducationCategoryPage} />
        <Route path='/master/documenttype' component={DocumentTypePage} />
        <Route path='/master/kycdocument' component={KycDocumentMasterPage} />
        <Route path='/payment/paymentWithdraw' component={PaymentWithdrawMasterPage} />
        <Route path='/master/terminalType' component={TerminalTypeMasterPage} />
        <Route path='/master/stock' component={StockMasterPage} />
        <Route path='/master/expirydate' component={ExpiryDateMasterPage} />
        <Route path='/master/exchange' component={ExchangeMasterPage} />
        <Route path='/master/strikeprice' component={StrikePriceMasterPage} />
        <Route path='/master/optionType' component={OptionTypeMasterPage} />
        <Route path='/master/charges' component={ChargesMasterPage} />
        <Route path='/master/tax' component={TaxMasterPage} />
        <Route path='/master/chargename' component={ChargesNameMasterPage} />
        <Route path='/master/customerExpenseType' component={CustomerExpenseTypeMasterPage} />
        <Route path='/organization/employee' component={EmployeeMasterPage} />
        <Route path='/organization/user' component={UserMasterPage} />
        <Route path='/organization/customer' component={CustomerMasterPage} />
        <Route path='/master/department' component={DepartmentMasterPage} />
        <Route path='/master/designation' component={DesignationMasterPage} />
        <Route path='/master/branch' component={BranchMasterPage} />
        <Route path='/master/bank' component={OrganizationBankMasterPage} />
        <Route path='/master/voucherType' component={JournalVoucherTypeMasterPage} />
        <Route path='/master/voucherCategory' component={VoucherCategoryMasterPage} />
        <Route path='/master/voucherGbSubCategory' component={VoucherGbSubCategoryMasterPage} />
        <Route path='/ledger' component={LedgerMasterPage} />
        <Route path='/jobs/jobCard' component={JobCardMasterPage} />
        <Route path='/jobs/job-order' component={JobTransactionMasterPage} />
        <Route path='/jobs/generate' component={JobGenerateMasterPage} />
        <Route path='/customerExpenses' component={CustomerExpenseMasterPage} />
        <Route path='/change/password' component={ChangePasswordPage} />
        <Route path='/payment/withdrawRequest' component={WithdrawRequestMasterPage} />
        <Route path='/payment/paymentWithdraw' component={PaymentWithdrawMasterPage} />
        <Route path='/payment/Fund' component={FundMasterPage} />
        <Route path='/transaction' component={TransactionMasterPage} />
        <Route path='/userDoc/userDetails' component={UserDetailsMasterPage} />
        <Route path='/userDoc/termSheet' component={TermSheetMasterPage} />
        <Route path='/userDoc/rentAgreement' component={RentAgreementMasterPage} />
        <Route path='/userDoc/jobbingAgreement' component={JobbingAgreementMasterPage} />
        <Route path='/terminalCode' component={TerminalCodePage} />
        <Route path='/openPosition' component={OpenPositionMasterPage} />
        <Route path='/bhavCopy' component={BhavCopyMasterPage} />
        <Route path='/journalVouchers' component={JournalVouchersMasterPage} />
        <Route path='/profit&Loss/Daily-Profit&Loss' component={ProfitLossMasterPage} />
        <Route path='/profit&Loss/Expiry-Daywise' component={ExpiryDaywiseMasterPage} />

        <Redirect from='/' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
