import React from 'react'
import {MenuItem} from './MenuItem'
import {shallowEqual, useSelector} from 'react-redux'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {useLayout} from '../../core'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

export function MenuInner() {
  const intl = useIntl()
  const {config, classes} = useLayout()
  const {aside} = config
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <div className='menu-item me-lg-1'>
        <div
          className='aside-logo flex-column-auto text-center'
          style={{width: '270px'}}
          id='kt_aside_logo'
        >
          {/* begin::Logo */}
          {aside.theme === 'dark' && (
            <Link to='/dashboard'>
              <img
                alt='Logo'
                className='h-30px logo'
                src={toAbsoluteUrl('/media/logos/Option_Tech_logo_png.png')}
              />
            </Link>
          )}
          {aside.theme === 'light' && (
            <Link to='/dashboard'>
              <img
                alt='Logo'
                className='h-30px logo'
                src={toAbsoluteUrl('/media/logos/Option_Tech_logo_png.png')}
              />
            </Link>
          )}
          {/* end::Logo */}
        </div>
        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
        {/* <span className='menu-link py-3'>
          Welcome to&nbsp;
          <span className='menu-title fs-5 text-primary'>
            {user.firstName}&nbsp;{user.lastName}
          </span>
        </span> */}
      </div>
      {/* ---------- Master -------------- */}
      <MenuInnerWithSub
        title='Master Page'
        to='/master'
        menuPlacement='bottom-start'
        menuTrigger='click'
        // dislpay={user.roleID === 1 || user.roleID === 2 || user.roleID === 3 ? '' : 'd-none'}
        dislpay={user.branchID === 1 ? '' : 'd-none'}  // Head Branch
      >
        <MenuItem to='/master/branch' title='Branch' hasBullet={true} />
        <MenuItem to='/master/charges' title='Charges' hasBullet={true} />
        <MenuItem to='/master/chargename' title='Charges Name' hasBullet={true} />
        <MenuItem to='/master/country' title='Country' hasBullet={true} />
        <MenuItem to='/master/customerExpenseType' title='Customer Expense Type' hasBullet={true} />
        <MenuItem to='/master/department' title='Department' hasBullet={true} />
        <MenuItem to='/master/designation' title='Designation' hasBullet={true} />
        <MenuItem to='/master/district' title='District' hasBullet={true} />
        <MenuItem to='/master/documenttype' title='Document Type' hasBullet={true} />
        <MenuItem to='/master/eduCategory' title='Education Category' hasBullet={true} />
        <MenuItem to='/master/eduDepartment' title='Education Department' hasBullet={true} />
        <MenuItem to='/master/exchange' title='Exchange' hasBullet={true} />
        <MenuItem to='/master/expirydate' title='Expiry Date' hasBullet={true} />
        <MenuItem to='/master/instrumentType' title='Instrument Type' hasBullet={true} />
        <MenuItem to='/master/voucherType' title='Journal Voucher Type' hasBullet={true} />
        <MenuItem to='/master/voucherCategory' title='Journal Voucher Category' hasBullet={true} />
        <MenuItem to='/master/voucherGbSubCategory' title='Voucher Sub Category' hasBullet={true} />
        <MenuItem to='/master/instrumentType' title='Instrument Type' hasBullet={true} />
        <MenuItem to='/master/kycdocument' title='Kyc Document' hasBullet={true} />
        <MenuItem to='/master/nationality' title='Nationality' hasBullet={true} />
        <MenuItem to='/master/optionType' title='Option Type' hasBullet={true} />
        <MenuItem to='/master/bank' title='Organization Bank' hasBullet={true} />
        <MenuItem to='/master/pincode' title='Pincode' hasBullet={true} />
        <MenuItem to='/master/scriptmanage' title='Script Manage' hasBullet={true} />
        <MenuItem to='/master/scriptsector' title='Script Sector' hasBullet={true} />
        <MenuItem to='/master/segmentType' title='Segment Type' hasBullet={true} />
        <MenuItem to='/master/state' title='State' hasBullet={true} />
        <MenuItem to='/master/strikeprice' title='Strike Price' hasBullet={true} />
        <MenuItem to='/master/stock' title='Stock Master' hasBullet={true} />
        <MenuItem to='/master/taluka' title='Taluka' hasBullet={true} />
        <MenuItem to='/master/tax' title='Tax' hasBullet={true} />
        <MenuItem to='/master/terminalType' title='Terminal Type' hasBullet={true} />
      </MenuInnerWithSub>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      {/* <MenuItem title='Layout Builder' to='/builder' /> */}
      {/* <MenuInnerWithSub
        title='Crafted'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        ------- PAGES ---------
        <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>

        -------- ACCOUNT ----------
        <MenuInnerWithSub
          title='Accounts'
          to='/crafted/accounts'
          fontIcon='bi-person'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub>

        ----------- ERRORS --------------
        <MenuInnerWithSub
          title='Errors'
          to='/error'
          fontIcon='bi-sticky'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub>

        ----------- Widgets --------------
        <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          fontIcon='bi-layers'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub> */}
      {/* <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
        ----------- PAGES --------------
        <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub> */}
      {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
